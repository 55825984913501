.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  background-color: #366C4D;
  /* background-color: #59ff34; */
}

.App-influencer {
  width: '100vw';
  height: '2.8em';
  text-align: center;
  /* display: flex;
  flex-direction: column; */
  border: solid #3CE8EC;
  border-radius: '1em';
}

.App-admin {
  width: '100vw';
  height: '2em';
  text-align: center;
  /* display: flex;
  flex-direction: column; */
  border: solid red;
  border-radius: '1em';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin: 0;
  padding: 0;
  width: 100vw;
}

.App-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100vw;
  color: #E3F1E9;
  background-color: #13271C;
}

.App-paper {
    padding: 15px;
    height: auto;
    max-width: 500px;
    width: 88vw;
    margin: 15px auto;
    display: block;
    background-color: #e0e9e4 !important;
}

.App-paper-full {
  padding: 15px;
  height: auto;
  max-width: 98vw;
  width: 88vw;
  margin: 15px auto;
  display: block;
  background-color: #e0e9e4 !important;

}

.App-link {
  color: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
